import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListasPermisosRestcService {

  constructor(private httpClient: HttpClient) { }

  solicitudesParaJefe(idJefe: number): Observable<any>{
    let path = environment.msListaPermisoJefe + "/" + idJefe;
  
    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.get(path, {headers});
  }

  solicitudesParaRH(): Observable<any>{
    let path = environment.msListaPermisoRh;
  
    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.get(path, {headers});
  }
}
