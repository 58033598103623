import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListaPermisoJefeFormComponent } from './views/listas-permisos/lista-permiso-jefe-form/lista-permiso-jefe-form.component';
import { ListaPermisoRhFormComponent } from './views/listas-permisos/lista-permiso-rh-form/lista-permiso-rh-form.component';
import { PermisoEmpleadoComponent } from './views/permiso-empleado/permiso-empleado/permiso-empleado.component';
import { HomeComponent } from './views/home/home.component';
import { PermisoRegComponent } from './views/permisoReg/permiso-reg/permiso-reg.component';
import { DashPermisoRhComponent } from './views/dashboard/dash-permiso-rh/dash-permiso-rh.component';
import { DashPermisoLiderComponent } from './views/dashboard/dash-permiso-lider/dash-permiso-lider.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: 'permiso-empleado', component: PermisoEmpleadoComponent },
  { path: 'permiso-lider', component: ListaPermisoJefeFormComponent },
  { path: 'permiso-rh', component: ListaPermisoRhFormComponent },
  { path: 'tipo-permiso', component: PermisoRegComponent },
  { path: 'dash-permisos-rh', component: DashPermisoRhComponent },
  { path: 'dash-permisos-lider', component: DashPermisoLiderComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
