<div class="p-grid frm-part">

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaInicio')">
        <br>
        <p class="color error" id="eFechaInicio">Selecciona un valor</p>
        <span class="p-float-label">
            <p-calendar id="fl-fecin" [(ngModel)]="item.fechaInicio" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [showIcon]="true" inputId="icono" [yearRange]="yearRange" [monthNavigator]="true" dataType="string"
                [disabledDays]="[0,6]"></p-calendar>
            <label for="fl-fecin" class="label-full"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Fecha de inicio</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaFin')">
        <br>
        <p class="color error" id="eFechaFin">Selecciona un valor</p>
        <span class="p-float-label">
            <p-calendar id="fl-fecfn" [(ngModel)]="item.fechaFin" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [showIcon]="true" inputId="icon" [yearRange]="yearRange" [monthNavigator]="true" dataType="string"
                [disabledDays]="[0,6]"></p-calendar>
            <label for="fl-fecfn" class="label-full"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Fecha fin</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idTipoPermiso')">
        <br>
        <p class="color error" id="eIdTipoPermiso">Selecciona un valor</p>
        <span class="p-float-label">
            <p-dropdown inputId="fl-tpperm" [(ngModel)]="item.idTipoPermiso" [options]="catTpPermiso"
                optionLabel="tipoPermiso" optionValue="id" [autoDisplayFirst]="false" autoWidth="false">
            </p-dropdown>
            <label for="fl-tpperm" class="label-full"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Tipo de permiso</label>
        </span>
        
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('motivo')">
        <br>
        <p class="color error" id="eMotivo">Ingresa un motivo</p>
        <span class="p-float-label">
            <textarea id="fl-mtvo" [(ngModel)]="item.motivo" rows="3" cols="30" pInputTextarea [pKeyFilter]="alphanumeric"
                 [style]="{'width':'100%'}"></textarea>
            <label for="fl-mtvo" class="label-full"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Motivo</label>
        </span>
    </div>

    <br>
    <br>
    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="operationMode == op.ADDNEW">
   
        <label style="font-size: 0.7em; color: red">* Campos requeridos</label>
    </div>

</div>

<p-toast></p-toast>