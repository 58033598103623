import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { MessageService } from 'primeng/api';
import { PermisoRegPsService } from 'src/app/procs/permisoReg/permiso-reg-ps.service';
import { Permiso } from 'src/app/api/permisoReg/permiso';
import { PermisoReg } from 'src/app/api/permisoReg/permiso-reg';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'axks-permiso-reg',
  templateUrl: './permiso-reg.component.html',
  styleUrls: ['./permiso-reg.component.scss']
})
export class PermisoRegComponent extends BaseMainComponent<PermisoRegPsService> implements OnInit {

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: PermisoRegPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as Permiso,
      minimumItem: {} as Permiso,
      registerItem: {} as PermisoReg
    } as ComponentItem;
    return empty;
  }

  getService(): PermisoRegPsService {
    return this.psService;
  }
  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      { header: 'Clave ', field: 'id', inAll: true },
      { header: 'Tipo de permiso', field: 'tipoPermiso', inAll: true },
      { header: 'Descripción', field: 'comentario', inAll: true }
    ] as ColumnDefinition[];
    return colsDef;
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Tipo de Permiso";
  }

}
