<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
    </p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">
    <axks-operations-bar (addNew)="addNew($event)" addNewLabel="Registrar" [addNewButton]="true" searchLabel="Buscar"
        [searchButton]="true" [sectionTitle]="getComponentTitle()">
    </axks-operations-bar>

    <div class="p-grid detail-sect" *ngIf="showDetail()">
        <axks-detail-tab [data]="workItem()" [colsDefinition]="colDefinition" [detailFields]="detailFields"
            class="axks-detail-tab-80">
        </axks-detail-tab>
    </div>

    <axks-permiso-reg-form #cmpForm *ngIf="showForm()" [item]="workItem()" [formSearch]="formSearch"
        [formFields]="formFields" [operationMode]="operationMode" [class]="'cmp-form-'+operationMode">

    </axks-permiso-reg-form>

    <axks-actions-bar (save)="save($event)" (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
        (print)="print($event)" (edit)="edit($event)" (delete)="delete($event)" (csvFile)="csvFile($event)"
        (pdfFile)="pdfFile($event)" (txtFile)="txtFile($event)" saveLabel="Guardar" cancelLabel="Cancelar"
        cleanLabel="Limpiar" [printButton]="false" [csvFileButton]="false" [pdfFileButton]="false"
        [txtFileButton]="false">
    </axks-actions-bar>

    <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        [listFields]="listFields" *ngIf="showResultList()">
    </axks-result-list>
</ng-container>

<p-toast></p-toast>