import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AutorizaPermiso } from 'src/app/api/autoriza-permiso/autoriza-permiso';
import { AutorizaPermisoReg } from 'src/app/api/autoriza-permiso/autoriza-permiso-reg';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutorizaPermisoRestcService {

  constructor(private httpClient: HttpClient) { }

  regAutorizaPermiso(item: AutorizaPermisoReg): Observable<AutorizaPermisoReg>{
    let path = environment.msAutorizaPermiso;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, item, { headers });
  }
}
