import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TipoPermiso } from 'src/app/api/permiso-empleado/tipo-permiso';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogosRestcService {

  constructor(private httpClient: HttpClient) { }

  catTipoPermiso(): Observable<TipoPermiso>{
    let path = environment.msTipoPermiso;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers });
  }
}
