import { version } from '../../package.json';

export const environment = {
  production: false,
  dev: false,
  uat: true,
  version: version,

  appId: "permisos-gui",
  empresas: [
    { id: 1, domain: 'uat.axkansoluciones.com' },
    { id: 2, domain: 'uat.sbsynergy.mx' },
    { id: 3, domain: 'uat.habitataxkan.com.mx' }
  ],

  sprofile: "https://sec.uat-ms.axkans.org/autentica/profile",
  msPermisoEmpleado: 'https://rh.uat-ms.axkans.org/rh/permiso-empleado',
  msTipoPermiso: 'https://rh.uat-ms.axkans.org/rh/tipoPermiso/all',
  msListaPermisoJefe: 'https://rh.uat-ms.axkans.org/rh/permiso-empleado/lista-permisos-lider',
  msListaPermisoRh: 'https://rh.uat-ms.axkans.org/rh/permiso-empleado/lista-permisos-RH',
  msPermisoReg: 'https://rh.uat-ms.axkans.org/rh/tipoPermiso',
  msAutorizaPermiso: 'https://rh.uat-ms.axkans.org/rh/permiso-empleado/autoriza-permiso',
  urlPermisoRh: 'https://permiso.uat-gui.axkans.org/permiso-rh',
  urlPermisoLider: 'https://permiso.uat-gui.axkans.org/permiso-lider',
  msDashboardLider: 'https://rh.uat-ms.axkans.org/rh/permiso-empleado/dashboard-lider',
  msDashboardRH: 'https://rh.uat-ms.axkans.org/rh/permiso-empleado/dashboard-RH',
  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }
};
