import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Dashboard } from 'src/app/api/dashboard/dashboard';
import { DashboardRhRestcService } from 'src/app/client/dashboard-rh/dashboard-rh-restc.service';
import { environment } from 'src/environments/environment';
import { ProfileC } from '@axks/components/lib/api/views/profile';

@Component({
  selector: 'axks-dash-permiso-rh',
  templateUrl: './dash-permiso-rh.component.html',
  styleUrls: ['./dash-permiso-rh.component.scss']
})
export class DashPermisoRhComponent extends BaseView implements OnInit {

  profile = this.getProfile() as ProfileC;
  dashboardrh: Dashboard[] = [];
  showContent: boolean;
  myLoadContent: boolean;
  mensajeSol: string;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private dashRHResct: DashboardRhRestcService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
    this.myLoadContent = false;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.obtenerSolicitudes();
    }, 1500);
  }

  obtenerSolicitudes() {
    this.dashRHResct.listaRH().subscribe(
      (data) => {
        this.dashboardrh = data as Dashboard[];
        this.myLoadContent = true;
        this.initContent();
      },
      (error) => {
        this.myLoadContent = true;
        this.mensajeSol = "Error. No se pudo consultar las solicitudes de permisos.";
      }
    );
  }

  initContent() {
    if (this.dashboardrh.length > 0) {
      this.showContent = true;
    }
    else {
      this.showContent = false;
      this.mensajeSol = "No hay solicitudes de permisos pendientes por revisar.";
    }
  }

  selSolicitud(event) {
    window.parent.postMessage({ for: "redirect", url: environment.urlPermisoRh }, "*");
  }

}
