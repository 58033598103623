<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">
    <h3>Permisos de colaboradores</h3>

    <p-table [value]="listaSol" [paginator]="true" [rows]="15" [responsive]="true">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center">
                <button type="button" pButton pRipple icon="pi pi-refresh" (click)="obtenerSolicitudes()"
                    class="p-button-info p-ml-auto" pTooltip="Refrescar solicitudes" tooltipPosition="left"></button>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Empleado</th>
                <th>Fecha inicio</th>
                <th>Fecha fin</th>
                <th>Tipo de permiso</th>
                <th>Motivo</th>
                <th>Acción</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-solicitud>
            <tr>
                <td>
                    <span class="p-column-title">Empleado</span>
                    {{solicitud.nombreEmpleado}}
                </td>
                <td>
                    <span class="p-column-title">Fecha inicio</span>
                    {{solicitud.fechaInicio | date: "dd 'de' MMMM 'de' yyyy": 'America/Mexico_City' : 'es-MX' }}
                </td>
                <td>
                    <span class="p-column-title">Fecha fin</span>
                    {{solicitud.fechaFin | date: "dd 'de' MMMM 'de' yyyy": 'America/Mexico_City' : 'es-MX' }}
                </td>
                <td>
                    <span class="p-column-title">Tipo de permiso</span>
                    {{solicitud.tipoPermiso}}
                </td>
                <td>
                    <span class="p-column-title">Motivo</span>
                    {{solicitud.motivo}}
                </td>
                <td style="text-align: center;">
                    <p-button type="button" (click)="visualizarSolicitud(solicitud)" label="Enterado"
                        styleClass="p-button-info"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</ng-container>

<p-toast></p-toast>