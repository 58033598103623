import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { RestClientModule } from '@axks/net';
import { HttpClientModule } from '@angular/common/http';
import { ActionsBarModule, CommonsModule, DetailTabModule, OperationsBarModule, ResultListModule, SessionClientProvider } from '@axks/components';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PermisoEmpleadoComponent } from './views/permiso-empleado/permiso-empleado/permiso-empleado.component';
import { PermisoEmpleadoFormComponent } from './views/permiso-empleado/permiso-empleado-form/permiso-empleado-form.component';

import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { MessageService } from 'primeng/api';
import { ListaPermisoJefeFormComponent } from './views/listas-permisos/lista-permiso-jefe-form/lista-permiso-jefe-form.component';

import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';

import { TreeTableModule } from 'primeng/treetable';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ListaPermisoRhFormComponent } from './views/listas-permisos/lista-permiso-rh-form/lista-permiso-rh-form.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { HomeComponent } from './views/home/home.component';
import { PermisoRegFormComponent } from './views/permisoReg/permiso-reg-form/permiso-reg-form.component';
import { PermisoRegComponent } from './views/permisoReg/permiso-reg/permiso-reg.component';
import { environment } from 'src/environments/environment';
import { DashPermisoRhComponent } from './views/dashboard/dash-permiso-rh/dash-permiso-rh.component';
import { DataViewModule } from 'primeng/dataview';
import { DashPermisoLiderComponent } from './views/dashboard/dash-permiso-lider/dash-permiso-lider.component';
import { CardModule } from 'primeng/card';
import { ListboxModule } from 'primeng/listbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    PermisoEmpleadoComponent,
    PermisoEmpleadoFormComponent,
    ListaPermisoJefeFormComponent,
    ListaPermisoRhFormComponent,
    HomeComponent,
    PermisoRegFormComponent,
    PermisoRegComponent,
    DashPermisoRhComponent,
    DashPermisoLiderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RestClientModule,
    HttpClientModule,
    ActionsBarModule,
    CommonsModule,
    DetailTabModule,
    OperationsBarModule,
    ResultListModule,
    FormsModule,
    BrowserAnimationsModule,
    CalendarModule,
    InputTextareaModule,
    DropdownModule,
    CheckboxModule,
    TableModule,
    ToastModule,
    ButtonModule,
    TreeTableModule,
    DialogModule,
    MultiSelectModule,
    InputTextModule,
    ContextMenuModule,
    KeyFilterModule,
    DataViewModule,
    CardModule,
    ListboxModule,
    ProgressSpinnerModule,
    TooltipModule
  ],
  providers: [MessageService, SessionClientProvider,
    { provide: 'env', useValue: environment }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
