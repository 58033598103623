import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { PermisoReg } from 'src/app/api/permisoReg/permiso-reg';

@Component({
  selector: 'axks-permiso-reg-form',
  templateUrl: './permiso-reg-form.component.html',
  styleUrls: ['./permiso-reg-form.component.scss']
})
export class PermisoRegFormComponent extends BaseView implements OnInit {

  @Input() item:PermisoReg;

  op = OperationMode;

  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if(!this.item){
      this.item = {};
    }
  }
}
