import { Component, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Dashboard } from 'src/app/api/dashboard/dashboard';
import { DashboardLiderRestcService } from 'src/app/client/dashboard-lider/dashboard-lider-restc.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-dash-permiso-lider',
  templateUrl: './dash-permiso-lider.component.html',
  styleUrls: ['./dash-permiso-lider.component.scss']
})
export class DashPermisoLiderComponent extends BaseView implements OnInit {

  profile = this.getProfile() as ProfileC;
  dashboard: Dashboard[] = [];
  showContent: boolean;
  myLoadContent: boolean;
  mensajeSol: string;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private dashJefeResct: DashboardLiderRestcService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
    this.myLoadContent = false;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.obtenerSolicitudes();
    }, 1500);
  }

  obtenerSolicitudes() {
    this.dashJefeResct.listaLider(this.profile.idEmpleado).subscribe(
      (data) => {
        this.dashboard = data as Dashboard[];
        this.myLoadContent = true;
        this.initContent();
      },
      (error) => {
        this.myLoadContent = true;
        this.mensajeSol = "Error. No se pudo consultar las solicitudes de permisos.";
      }
    );
  }

  initContent() {
    if (this.dashboard.length > 0) {
      this.showContent = true;
    }
    else {
      this.showContent = false;
      this.mensajeSol = "No hay solicitudes de permisos pendientes por revisar.";
    }
  }

  selSolicitud(event) {
    window.parent.postMessage({ for: "redirect", url: environment.urlPermisoLider }, "*");
  }
}
