import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { PermisoRegRestcService } from 'src/app/client/permisoReg/permiso-reg-restc.service';

@Injectable({
  providedIn: 'root'
})
export class PermisoRegPsService extends BaseProcess<PermisoRegRestcService> {

  constructor(httpClient: HttpClient, crest: PermisoRegRestcService) {
    super(httpClient, crest);
  }
}
