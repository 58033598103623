import { Component, Input, OnInit } from '@angular/core';
import { ListasPermisosRestcService } from 'src/app/client/listas-permisos/listas-permisos-restc.service';
import { Usuario } from 'src/app/api/usuario/usuario';
import { MessageService } from 'primeng/api';
import { ListaPermisoJefe } from 'src/app/api/listas-permisos/listaPermiso-jefe';
import { AutorizaPermiso } from 'src/app/api/autoriza-permiso/autoriza-permiso';
import { AutorizaPermisoRestcService } from 'src/app/client/autoriza-permiso/autoriza-permiso-restc.service';
import { AutorizaPermisoReg } from 'src/app/api/autoriza-permiso/autoriza-permiso-reg';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProfileC } from '@axks/components/lib/api/views/profile';


@Component({
  selector: 'axks-lista-permiso-jefe-form',
  templateUrl: './lista-permiso-jefe-form.component.html',
  styleUrls: ['./lista-permiso-jefe-form.component.scss']
})
export class ListaPermisoJefeFormComponent extends BaseView implements OnInit {

  listaSol: ListaPermisoJefe[] = [];
  autorizaPermisoReg: AutorizaPermisoReg = {};
  autorizaPermiso: AutorizaPermiso[] = [];
  perfil = this.getProfile() as ProfileC;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private listaRestc: ListasPermisosRestcService,
    private autorizaPermRestc: AutorizaPermisoRestcService) {

    super(screenModeService, deviceService, messageService, sessionProvier)
  }

  ngOnInit(): void {
    this.obtenerSolicitudes();
  }

  obtenerSolicitudes() {
    this.showProgress = true;

    this.listaRestc.solicitudesParaJefe(this.perfil.idEmpleado).subscribe(
      (data) => {
        this.listaSol = data as ListaPermisoJefe[];
        this.showProgress = false;
        if (this.listaSol.length === 0) {
          this.messageService.add({ severity: 'info', summary: 'Sin solicitudes', detail: 'No hay ninguna solicitud de permisos por revisar.' });
        }
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
      }
    );
  }

  autorizar(valor: any) {

    this.autorizaPermisoReg = {
      idPermiso: valor.idPermiso,
      autorizacion: true,
      idJefe: this.perfil.idEmpleado,
      idEmpleadoRH: 0
    }

    this.showProgress = true;

    this.autorizaPermRestc.regAutorizaPermiso(this.autorizaPermisoReg).subscribe(
      (data) => {
        this.autorizaPermiso = data as AutorizaPermiso[];
        this.messageService.add({ severity: 'success', summary: 'Solicitud aceptada', detail: 'La solicitud se autorizó correctamente.' });
        this.showProgress = false;
        setTimeout(function () { location.reload(); }, 2000);
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo envíar la solicitud.' });
        this.showProgress = false;
      }
    );
  }

  rechazar(valor: any) {

    this.autorizaPermisoReg = {
      idPermiso: valor.idPermiso,
      autorizacion: false,
      idJefe: this.perfil.idEmpleado,
      idEmpleadoRH: 0
    }

    this.showProgress = true;

    this.autorizaPermRestc.regAutorizaPermiso(this.autorizaPermisoReg).subscribe(
      (data) => {
        this.autorizaPermiso = data as AutorizaPermiso[];
        this.messageService.add({ severity: 'success', summary: 'Solicitud rechazada', detail: 'La solicitud se rechazo correctamente.' });
        this.showProgress = false;
        setTimeout(function () { location.reload(); }, 2000);
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo envíar la solicitud.' });
        this.showProgress = false;
      }
    );
  }

}
