import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { PermisoEmpleado } from 'src/app/api/permiso-empleado/permiso-empleado';
import { PermisoEmpleadoReg } from 'src/app/api/permiso-empleado/permiso-empleado-reg';
import { PermisoEmpleadoPsService } from 'src/app/procs/permiso-empleado/permiso-empleado-ps.service';

@Component({
  selector: 'axks-permiso-empleado',
  templateUrl: './permiso-empleado.component.html',
  styleUrls: ['./permiso-empleado.component.scss']
})
export class PermisoEmpleadoComponent extends BaseMainComponent<PermisoEmpleadoPsService> implements OnInit {

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: PermisoEmpleadoPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cargarTabla();
  }

  initEmptyItem(): ComponentItem {
    let prof = this.getProfile() as ProfileC;
    let empty = {
      item: {
        idEmpleado: prof.idEmpleado
      } as PermisoEmpleado,
      minimumItem: {
        idEmpleado: prof.idEmpleado
      } as PermisoEmpleado,
      registerItem: {
        idEmpleado: prof.idEmpleado
      } as PermisoEmpleadoReg
    } as ComponentItem;
    return empty;
  }

  getService(): PermisoEmpleadoPsService {
    return this.psService;
  }
  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      { header: 'Num. Solicitud', field: 'idPermiso', inAll: true },
      { header: 'Motivo', field: 'motivo', inDetail: true, inResultList: true },
      { header: 'Tipo de Permiso', field: 'tipoPermiso', inDetail: true, inResultList: true },
      { header: 'Fecha inicio', field: 'fechaInicio', inAll: true, formatFunction: this.formatoFecha },
      { header: 'Fecha fin', field: 'fechaFin', inAll: true, formatFunction: this.formatoFecha },
      { header: 'Estatus', field: 'autorizaJefe', inDetail: true, inResultList: true, formatFunction: this.formatBoolean },
      { header: 'Fecha de autorización/rechazo', field: 'fechaAutorizaJefe', inDetail: true, inResultList: false, formatFunction: this.formatoFecha }
    ] as ColumnDefinition[];
    return colsDef;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.idPermiso || this.item.item.idPermiso;
  }

  getComponentTitle(): string {
    return "Permiso";
  }

  formatoFecha(value: string) {
    let pipe = new DatePipe('es-MX');
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy");
  }

  formatBoolean(value: any): string {
    if (value !== undefined && value !== "" && value !== null) {
      return (value == true ? 'Autorizado por el líder' : 'Rechazado por el líder');
    }
    else {
      return 'En revisión...';
    }
  }

  componentsFields() {
    super.componentsFields();
    this.formSearch = ['fechaInicio', 'fechaFin', 'idTipoPermiso'];
    this.formFields = [];
  }


  save(event: any) {
    var mostrar1: any = document.getElementById('eFechaInicio');
    var mostrar2: any = document.getElementById('eFechaFin');
    var mostrar3: any = document.getElementById('eIdTipoPermiso');
    var mostrar4: any = document.getElementById('eMotivo');


    if (!this.item.registerItem.fechaInicio || this.item.registerItem.fechaInicio == "" ||
      !this.item.registerItem.fechaFin || this.item.registerItem.fechaFin == "" ||
      !this.item.registerItem.idTipoPermiso || this.item.registerItem.idTipoPermiso == "" ||
      !this.item.registerItem.motivo || this.item.registerItem.motivo == "") {

      this.messageService.add({ severity: 'error', summary: 'Llena todos los campos', detail: '' });

      if (!this.item.registerItem.fechaInicio) {
        mostrar1.classList.add('mostrar-error');
        setTimeout(function () { mostrar1.classList.remove('mostrar-error'); }, 2000);
      }
      if (!this.item.registerItem.fechaFin) {
        mostrar2.classList.add('mostrar-error');
        setTimeout(function () { mostrar2.classList.remove('mostrar-error'); }, 2000);
      }
      if (!this.item.registerItem.idTipoPermiso) {
        mostrar3.classList.add('mostrar-error');
        setTimeout(function () { mostrar3.classList.remove('mostrar-error'); }, 2000);
      }
      if (!this.item.registerItem.motivo) {
        mostrar4.classList.add('mostrar-error');
        setTimeout(function () { mostrar4.classList.remove('mostrar-error'); }, 2000);
      }

    } else {
      super.save(this.item.registerItem);
      this.messageService.add({ severity: 'success', summary: 'Permiso Registrado', detail: 'Se registro correctamente el permiso' });
    }
  }

  cargarTabla() {
    this.getService().find(this.item.item).subscribe(
      (data) => {
        this.resultList = data;
      },
      (error) => {
        let mess = error.error as Message;
        if (mess.code == 5000) {
          this.infoMessage("Permisos", "Actualmente no tienes permiso.")
        }
      }
    );
  }

}