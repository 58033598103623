<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
        <br>
        <span class="p-float-label">
            <input id="fl-tpper" type="text" pInputText [(ngModel)]="item.tipoPermiso">
            <label for="fl-tpper" class="label-full"><span *ngIf="operationMode == op.ADDNEW"
                    [style]="{'color':'red'}">*</span> Tipo de permiso (Título)</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
        <br>
        <span class="p-float-label">
            <input id="fl-desc" type="text" pInputText [(ngModel)]="item.comentario">
            <label for="fl-desc" class="label-full"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span>
                Descripción</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="operationMode == op.ADDNEW">
        <br>
        <label style="font-size: 0.7em; color: red">* Campos requeridos</label>
    </div>
</div>