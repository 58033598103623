import { formatDate, registerLocaleData } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';

import localeEsMx from '@angular/common/locales/es-MX';/*localizacion para dar formato*/
import { PermisoEmpleado } from 'src/app/api/permiso-empleado/permiso-empleado';
import { Usuario } from 'src/app/api/usuario/usuario';
import { CatalogosRestcService } from 'src/app/client/permiso-empleado/catalogos-restc.service';
import { TipoPermiso } from 'src/app/api/permiso-empleado/tipo-permiso';
import { MessageService } from 'primeng/api';
registerLocaleData(localeEsMx, 'es-MX');

@Component({
  selector: 'axks-permiso-empleado-form',
  templateUrl: './permiso-empleado-form.component.html',
  styleUrls: ['./permiso-empleado-form.component.scss']
})
export class PermisoEmpleadoFormComponent extends BaseView implements OnInit {

  @Input() item: PermisoEmpleado;

  yearRange: any;
  catTpPermiso: TipoPermiso[] = [];
  alphanumeric: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°+|*¬¸~─,¨:;-]/;
  alphabetic: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°1234567890+|*¬¸~─,¨:;-]/;
  op = OperationMode;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private catRest: CatalogosRestcService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {

    if (!this.item) {
      this.item = {};
    }

    this.initDates();
    this.initCatalogos();
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 60) + ':' + (year + 60);
  }

  initCatalogos() {

    this.catRest.catTipoPermiso().subscribe(
      data => {
        this.catTpPermiso = data as TipoPermiso[];
      }
    );
  }

  formatoFecha(value: string) {
    let d = Date.parse(value);

    return formatDate(d, 'yyyy/MM/dd', 'es-MX');
  }

}  